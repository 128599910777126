import {ReactNode} from "react";
import { cn } from "./ui/lib/utils";
import {Apps} from "~/constant/front.constant";

export const EmojiText = ({ title, emoji, emojiBackgroundColor, }: { title: string; emoji: string; emojiBackgroundColor: string; }) => {
  return (
    <div className={"flex flex-row items-center justify-start w-full gap-x-4 md:gap-x-6 mx-auto"}>
      <div style={{ backgroundColor: emojiBackgroundColor }} className={"rounded-full size-[36px] text-[18px] md:size-[48px] md:text-[24px] flex items-center justify-center"}>
        {emoji}
      </div>
      <div className={"text-md md:text-xl font-poppins-light"}>
        {title}
      </div>
    </div>
  )
}

export const ListImage = () => {
  return (
    <div
      className={"bg-[url('/img/arrow.webp')] bg-no-repeat bg-contain h-3"}
    />
  );
}

export const Li = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={"flex flex-row items-center gap-x-1"}
    >
      <div className={"w-6"}>
        <ListImage />
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

export const H2 = ({ className, children }: { className?: string, children: ReactNode }) => (
  <div className={"max-w-[65ch]"}>
    <h2 className={cn("text-22px md:text-[30px] font-semibold text-neutral-800", className)}>
      {children}
    </h2>
  </div>
);

export const H2Content = ({ children, className, }: { children: ReactNode, className?: string, }) => (
  <p className={cn("max-w-[65ch] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed", className)}>
    {children}
  </p>
);

const Section = ({ children }: { children: ReactNode; }) => (
  <section className="w-full py-6 md:py-12 lg:py-12">
    {children}
  </section>
);

export const ExplanationWithScreenshot = ({ title, children, img }: { title: ReactNode, children: ReactNode, img: string }) => (
  <Section>
    <div className="container grid gap-6 px-4 md:px-12 lg:grid-cols-2 lg:gap-10">
      <div className="space-y-4">
        <H2>
          {title}
        </H2>
        <H2Content className="space-y-4">
          {children}
        </H2Content>
      </div>
      <img
        src={img}
        alt=""
        className="mx-auto md:aspect-video overflow-hidden rounded-xl md:object-contain sm:w-full md:h-full md:w-auto md:min-h-[560px]"
      />
    </div>
  </Section>
);

export const EmojiListWithImage = ({ img, children }: { children: ReactNode, img: string }) => (
  <Section>
    <div className="container grid gap-6 px-4 md:px-12 grid-rows-1 lg:grid-rows-1 lg:grid-cols-2 md:gap-10">
      <img
        src={img}
        alt="Workflow Image"
        className="mx-auto md:aspect-video overflow-hidden rounded-xl md:object-contain sm:w-full md:h-full md:w-auto lg:min-h-[560px] hidden md:block"
      />
      <div className={"space-y-4 font-semibold flex flex-col items-start justify-center"}>
        {children}
      </div>
    </div>
  </Section>
);

export const DownloadApp = ({ logoUrl, appStoreLink, androidStoreLink }: { logoUrl: string, appStoreLink: string, androidStoreLink: string }) => (
  <Section>
    <div className="grid gap-6 px-4 md:px-6 lg:grid-rows-1 lg:gap-16">
      <div className="text-center">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-4xl">Téléchargez l’application dès maintenant</h2>
      </div>

      <div>
        <img
          src={logoUrl}
          alt=""
          className={"size-[80px] md:size-[160px] bg-[#ccc] mx-auto rounded-[15px]"}
        />
      </div>

      <div
        className="flex flex-col md:flex-row items-center justify-center px-4 space-y-6 md:space-y-0 space-x-0 md:space-x-4">
        <a
          href={appStoreLink}
          target="_blank"
          className={"underline flex flex-col items-center justify-center text-[black] text-lg"}
        >
          <img
            src="/img/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg"
            alt="Download on App Store"
            className={"align-middle mr-[5px] w-40 md:w-60"}
          />
        </a>

        <a
          href={androidStoreLink}
          target="_blank"
          className={"underline flex flex-col items-center justify-center text-[black] text-lg"}
        >
          <img
            src="/img/GetItOnGooglePlay_Badge_Web_color_French.webp"
            alt="Download on Play Store"
            className={"align-middle mr-[5px] w-40 md:w-60"}
          />
        </a>
      </div>
    </div>
  </Section>
);

export const EndPageSpacing = () => (
  <div className={"py-8 md:py-12 lg:py-16"} />
);

export const HeroSpacing = () => (
  <div className={"h-8"} />
);